<div class="nav-top">
  <div class="row">
    <div class="column">
      <rv-titlebar
        (onTitleUpdate)="updateProjectTitle($event)"
      >
      </rv-titlebar>
    </div>
  </div>
</div>
<div class="nav-wrapper">
  <div class="row">
    <div class="column">
      <rv-toolbar
        [hasSelectedAnnotations]="hasSelectedAnnotations"
        [hasClipboardAnnotations]="hasClipboardAnnotations"
        [hasRedo]="hasRedo"
        [hasUndo]="hasUndo"
        [hasTracks]="hasTracks"
        [hasActiveTrack]="hasActiveTrack"
        (onAddAnnotation)="addAnnotation()"
        (onDeleteAnnotation)="deleteAnnotation()"
        (onCopyAnnotation)="copyAnnotation()"
        (onPasteAnnotation)="pasteAnnotation()"
        (onUndoAction)="undoAction()"
        (onRedoAction)="redoAction()"
        (onImportProject)="importProject($event)"
        (onImportVideo)="importVideo($event)"
        (onExportProject)="exportProject()"
        (onExportProjectAsText)="exportProjectAsText($event)"
        (onResetProject)="resetProject()"
        (onNewProject)="newProject()"
        [currentAnnotationsOnly]="currentAnnotationsOnly" (onCurrentAnnotationsOnlyChange)="currentAnnotationsOnlyChange($event)"
        [search]="search" (onSearchChange)="searchChange($event)"
        [applyToTimeline]="applyToTimeline" (onApplyToTimelineChange)="applyToTimelineChange($event)">
      </rv-toolbar>
    </div>
  </div>
</div>
<div class="row player-inspector-row">
  <div class="small-12 medium-6 large-8 column">
    <rv-player></rv-player>
  </div>
  <div class="small-12 medium-6 large-4 column">
    <rv-inspector></rv-inspector>
  </div>
  <div class="small-12 column">
    <rv-timeline></rv-timeline>
  </div>
  <div class="small-12 column">
    <rv-footer></rv-footer>
  </div>
</div>
<rv-progress-modal class="reveal" id="progress-modal" data-reveal data-close-on-click="false" data-close-on-esc="false"></rv-progress-modal>
<rv-medi-archive (onResetProject)="resetProject($event)" (onImportVideo)="importVideo($event)" (onImportProjectMeta)="importProjectMeta($event)" class="reveal" id="medi-archive-modal" data-reveal data-close-on-click="false" data-close-on-esc="false"></rv-medi-archive>
