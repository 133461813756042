<div>
  <h2><i class="ion-ios-folder icon-title" title="Project Settings"></i>Project</h2>
  <button class="close-button" data-close aria-label="Close modal" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="row">
  <div class="column">
    <h3 class="title-settings">New project</h3>
    <a class="button-new" href="#" (click)="newProject()">New</a>
    <h3 class="title-settings">Open Project</h3>
    <input type="file" name="projectFile" id="projectFile" class="inputfile" accept=".rv" (change)="importProject($event)">
    <label for="projectFile">Open...</label>
    <h3 class="title-settings">Change Video File</h3>
    <input type="file" name="videoChooser" id="videoChooser" class="inputfile" accept="video/*" (change)="importVideo($event)">
    <label for="videoChooser">Choose a file...</label>
  </div>
  <div class="column">
    <h3 class="title-settings">Reset to sample project</h3>
    <a class="button-reset" href="#" (click)="resetProject()">Reset</a>
    <h3 class="title-settings">Export Project</h3>
    <a class="button-export" href="#" (click)="exportProject()">Export</a>
  </div>
  <div class="column">
    <h3 class="title-settings">Video File</h3>
    <div>{{videoName}} ({{videoSize}} MB)</div>
    <h3 class="title-settings">Duration</h3>
    <div>{{videoDuration | date:'HH:mm:ss'}} (HH:MM:SS)</div>
    <h3 class="title-settings">Annotations</h3>
    <div>{{annotationsCount}} (on {{tracksCount}} Tracks)</div>
    <h3 class="title-settings">Default annotation duration</h3>
    <div class="row">
      <div class="column">
        <input type="number" [(ngModel)]="defaultAnnotationDuration" [min]="defaultAnnotationDurationMin" [max]="defaultAnnotationDurationMax" (ngModelChange)="updateDefaultAnnotationDuration()" class="default-annotation-duration-input" />
        <span class="default-annotation-duration-label">seconds</span>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="column">
    <div style="display: none;">
      <h3 class="title-settings">HTTP URL</h3>
      <input type="text" [(ngModel)]="customURL">
      <button class="button-importurl" (click)="openURL('customurl', customURL)">Open</button>
    </div>
    <div style="display: none;">
      <h3 class="title-settings">YouTube</h3>
      <input type="text" [(ngModel)]="youtubeURL">
      <button class="button-importurl" (click)="openURL('youtube', youtubeURL)">Open</button>
    </div>
    <div style="display: none;">
      <h3 class="title-settings">Vimeo</h3>
      <input type="text" [(ngModel)]="vimeoURL">
      <button class="button-importurl" (click)="openURL('vimeo', vimeoURL)">Open</button>
    </div>
  </div>
</div>
<div class="row">
  <div class="column">
    <h3 class="title-settings">Export Annotation Text</h3>
    <div class="row">
      <div class="column"><a class="button-export" href="#" (click)="exportProjectAsText('txt')">Plain Text (.txt)</a> </div>
      <div class="column"><a class="button-export" href="#" (click)="exportProjectAsText('csv')">Table (.csv)</a> </div>
      <div class="column"><a class="button-export" href="#" (click)="exportProjectAsText('srt')">Subtitle File (.srt)</a></div>
    </div>
  </div>
  <div class="column"></div>
</div>
<div class="row">
  <div class="column">
    <h3 class="title-information">Information</h3>
    <p>Preferred Video Formats: H.264 and AAC in MP4 Container.</p>
    <p>Research Video is auto-saving, no need to save manually. If you want to send the project to somebody or preserve it for later use, simply export it as an archive.</p>
    <p>Find general instructions in this <a href="https://docs.google.com/document/d/1SBC6mqfSwlYuB8H3-UR_MwBDspslNPMptinv_hI2MQ4/edit" target="_blank">manual</a>.</p>
    <p>To share your feedback, feel free to start a new issue on <a href="https://github.com/StudioProcess/rvp/issues" target="_blank">github</a> or join the conversation in our <a href="https://docs.google.com/document/d/138X8Mwrqro1Um0N85-0xUM5yru1hoRt6Rob0vzt7svE/edit" target="_blank">public document</a>.</p>
  </div>
</div>
<div class="row align-center">
  <div class="column shrink">
    <rv-version></rv-version>
  </div>
</div>
