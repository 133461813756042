<div class="row toolbar-color" (mousedown)="toolbarClick($event)">
  <div class="small-12 medium-12 large-8 column">
    <button (click)="actionBtnClick($event, 'add_annotation')" [disabled]="!hasTracks || !hasActiveTrack || viewmode_active">
      <i class="ion-md-add-circle-outline" title="Add Annotation"></i><span class="show-for-medium"> Add</span>
    </button>
    <button (mousedown)="actionBtnClick($event, 'delete_annotation')" [disabled]="!hasSelectedAnnotations || viewmode_active">
      <i class="ion-md-remove-circle-outline" title="Delete Annotation"></i><span class="show-for-medium"> Delete</span>
    </button>
    <button (mousedown)="actionBtnClick($event, 'copy_annotation')" [disabled]="!hasSelectedAnnotations || viewmode_active">
      <i class="ion-md-copy" title="Copy Annotation"></i><span class="show-for-medium"> Copy</span>
    </button>
    <button (mousedown)="actionBtnClick($event, 'paste_annotation')" [disabled]="!hasClipboardAnnotations || viewmode_active">
      <i class="ion-md-clipboard" title="Paste Annotation"></i><span class="show-for-medium"> Paste</span>
    </button>
    <button data-open="tag-add-modal" [disabled]="!hasSelectedAnnotations || viewmode_active">
      <i>#</i> Tag
    </button>
    <rv-tag-add-modal class="reveal" id="tag-add-modal" data-reveal>
    </rv-tag-add-modal>

    <button (click)="actionBtnClick($event, 'undo_action')" [disabled]="!hasUndo || viewmode_active">
      <i class="ion-md-undo" title="Undo Action"></i><span class="show-for-medium"> Undo</span>
    </button>
    <button (click)="actionBtnClick($event, 'redo_action')" [disabled]="!hasRedo || viewmode_active">
      <i class="ion-md-redo" title="Redo Action"></i><span class="show-for-medium"> Redo</span>
    </button>

    <rv-projectbtn data-open="settings-reveal" class="modalButton"></rv-projectbtn>
    <rv-projectmodal class="reveal" data-reveal
      [attr.id]="viewmode_active ? 'settings-reveal' : 'settings-reveal'"
      (onImportProject)="importProject($event)"
      (onImportVideo)="importVideo($event)"
      (onExportProject)="exportProject()"
      (onExportProjectAsText)="exportProjectAsText($event)"
      (onResetProject)="resetProject()"
      (onNewProject)="newProject()">
    </rv-projectmodal>

    <form class="leftForm" [formGroup]="leftForm" novalidate>
      <div class="checkbox">
        <label>
          <input formControlName="currentAnnotationsOnly" type="checkbox">
          <span class="custom-checkbox secondary"><i class="icon-check"></i></span>
          Current <span class="show-for-large">annotations</span> only
        </label>
      </div>
    </form>
  </div>
  <div class="small-12 medium-12 large-4 column">
    <form [formGroup]="rightForm" novalidate>
      <div class="searchBox-wrapper">
        <!--
        <input class="searchBox" #search formControlName="search" type="text" placeholder="Search..." (focus)="search.placeholder=''" (blur)="search.placeholder='Search...'"/>
        <i class="close-icon ion-md-close" [hidden]="!(search.value.length > 0)" (click)="clearSearch()"></i>
        -->
        <div #search contenteditable="true" class="searchBox contenteditable" formControlName="search" placeholder="Search..." (focus)="search.placeholder=''" placeholder="Search..."></div>
        <i class="search-icon ion-md-search"></i>
        <i class="close-icon ion-md-close" [hidden]="!(search.innerHTML.length > 0)" (click)="clearSearch()"></i>
      </div>
      <div class="applyToTimeline-wrapper">
        <div class="checkbox">
          <label>
            <input formControlName="applyToTimeline" type="checkbox"/>
            <span class="custom-checkbox secondary"><i class="icon-check"></i></span>
            Apply to timeline
          </label>
        </div>
      </div>
    </form>
  </div>
</div>
