<div>
  <h2>Add Tag</h2>
  <button class="close-button" data-close aria-label="Close modal" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="row">
  <div class="column">
    #<input #addHashtagInput class="add-new-hashtag-input" placeholder="enter new hashtag" (keyup)="addNewHashtag($event)" >
    <ul class="tagging-list">
      <li *ngFor="let hashtag of hashtags"
        [value]="hashtag"
        [type]="hastag"
        (click)="selectAddHashtag($event, hashtag)">
        {{hashtag}}
      </li>
    </ul>
  </div>
</div>
<div class="row" style="margin-top: 20px;">
  <div class="columns small-8">
    <strong>{{selectedHashtag}}</strong>
  </div>
  <div class="columns">
    <a class="button-add-hashtag" href="#" data-close (click)="addHashtag(selectedHashtag)">Add</a>
  </div>
</div>
