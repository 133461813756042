<rv-logo></rv-logo>
<form class="project-title" [formGroup]="pnform" novalidate>
  <div class="title-wrapper">
    <input
      #projecttitle
      type="text"
      title="Project Title"
      formControlName="project_title"
      maxlength="350"
      AutoSizeInput
      [maxWidth]="getMaxWidth()"
      [readonly]="viewmode_active"
      [setParentWidth]="true"
      [includeBorders]="true"
      [extraWidth]="2"
      [class.my-class]=""
      [ngClass]="{'initial-title': project_initial_title}"
      (click)="!project_initial_title || projecttitle.select()"
    >
  </div>
</form>
<rv-viewmode></rv-viewmode>
