<div #scrollbar class="scrollbar-wrapper">
  <rv-handlebar #handlebar [containerRect]="scrollbarRect" [caption]="scrollbarCaption" [left]="scrollbarLeft"
    [width]="scrollbarWidth" [ommit_viewmode]="true">
  </rv-handlebar>
</div>
<div #timelineWrapper class="timeline-wrapper">
  <rv-track *ngFor="let track of timeline?.get('tracks', []); index as i; trackBy: trackByFunc;"
    [class.isHidden]="!track.get('isVisible', null)"
    [data]="track"
    [trackIndex]="i"
    [numTracks]="getNumTracks()"
    [totalDuration]="timeline.duration"
    [scrollSettings]="scrollSettings"
    [selectedAnnotations]="selectedAnnotations"
    [playerCurrentTime]="playerCurrentTime"
    (onUpdateTrack)="updateTrack($event)"
    (onDeleteTrack)="!viewmode_active && deleteTrack($event)"
    (onDuplicateTrack)="!viewmode_active && duplicateTrack($event)"
    (onInsertAtTrack)="!viewmode_active && insertTrackAt($event)"
    (onSetActiveTrack)="!viewmode_active && setActiveTrack($event)"
    (onAddAnnotation)="!viewmode_active && addAnnotation($event)"
    (onToggleTrackVisibility)="toggleTrackVisibility($event)"
    (onUpdateAnnotation)="!viewmode_active && updateAnnotation($event)"
    (onSelectAnnotation)="selectAnnotation($event)"
    (onDblClickAnnotation)="focusAnnotation($event)">
  </rv-track>
  <div #playheadOverflow class="playhead-wrapper">
    <div #playheadZoom class="playhead-zoom" [style.width.%]="pZoom*100">
      <rv-playhead [left]="playerPos*100" [currentTime]="playerCurrentTime"></rv-playhead>
    </div>
  </div>
</div>
<button class="ion-md-add add-track" title="Add new Track" alt="Add new Track"
  [ngStyle]="{'color': (!viewmode_active) ? '' : '#CCC' }" (click)="!viewmode_active && addTrack()">
  Add Track</button>
<!-- {{selectedAnnotationId}} -->
<!-- <pre>{{timeline?.tracks|json}}</pre> -->
