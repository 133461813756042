<div class="active-track" *ngIf="data.get('isActive', null)"></div>
<div class="row title-row">
  <div class="column shrink" [formGroup]="form">
    <div class="track-show-hide" (mousedown)="toggleTrackVisibility($event, trackIndex, data.get('isVisible', null))"><i class="icon" [className]="data.get('isVisible', null) ? 'ion-md-arrow-dropdown' : 'ion-md-arrow-dropright'" title="{{data.get('isVisible', null) ? 'Hide' : 'Show'}} track"></i></div>
    <div #trackBtn class="track-color" [style.color]="data.get('color', null)">
      <i class="ion-ios-radio-button-on" title="Track Color"></i>
    </div>
    <input #title class="title-input" placeholder="Track Title" title="Track Title" formControlName="title" [readonly]="viewmode_active">
  </div>
  <div class="column shrink track-options" *ngIf="!viewmode_active">
    <i class="icon" [className]="data.get('isVisible', null) ? 'ion-md-eye-off' : 'ion-md-eye'" title="{{data.get('isVisible', null) ? 'Hide' : 'Show'}} track" (mousedown)="toggleTrackVisibility($event, trackIndex, data.get('isVisible', null))"></i>
    <i class="ion-md-copy track-copy-icon" title="Duplicate" alt="Duplicate" (mousedown)="duplicateTrack($event, trackIndex)"></i>
    <i *ngIf="trackIndex > 0" class="ion-ios-arrow-dropup" title="Move Up" alt="Move Up" (mousedown)="moveTrack($event, trackIndex, 'up')"></i>
    <i *ngIf="trackIndex < numTracks-1" class="ion-ios-arrow-dropdown" title="Move Down" alt="Move Down" (mousedown)="moveTrack($event, trackIndex, 'down')"></i>
    <i class="ion-md-close track-delete-icon" title="Delete Track" alt="Delete Track" (mousedown)="deleteTrackHandler($event)"></i>
  </div>
</div>
<div #trackOverflow class="track-overflow">
  <div #zoomContainer class="track-zoom" [style.width.%]="zoom*100">
    <div *ngFor="let stack of data.get('annotationStacks', []); index as stackIndex; trackBy: outerTrackByFunc;">
      <div class="annotations-wrapper" (dblclick)="addAnnotation($event, stackIndex)" *ngIf="data.get('isVisible', null)">
        <rv-handlebar *ngFor="let annotation of stack; index as i; trackBy: innerTrackByFunc;"
          [annotation]="annotation"
          [trackIndex]="data.get('id', null)"
          [trackIsVisible]="data.get('isVisible', null)"
          [is_viewmode]="viewmode_active"
          [ommit_viewmode]="false"
          [display]="getIsDisplayed(annotation)"
          [opacity]="getAnnotationOpacity(annotation)"
          [pointerEvents]="getAnnotationPointerEvents(annotation)"
          [containerRect]="zoomContainerRect"
          [caption]="getAnnotationTitle(annotation)"
          [left]="getAnnotationPosition(annotation)"
          [width]="getAnnotationWidth(annotation)"
          [isSelected]="isSelectedAnnotation(annotation)"
          [hasPointerElement]="hasPointerElement(annotation)"
          [annotationStartTime]="getAnnotationStartTime(annotation)"
          [annotationEndTime]="getAnnotationEndTime(annotation)"
          [playerCurrentTime]="playerCurrentTime"
          (onHandlebarUpdate)="updateHandlebar($event, i, stackIndex)"
          (mousedown)="annotationClick($event, annotation, stackIndex)"
          (onDblClick)="dblClick(annotation)">
        </rv-handlebar>
      </div>
    </div>
  </div>
</div>
